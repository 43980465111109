
import { Vue, Component } from 'vue-property-decorator';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import { Mutation, Action, State, Getter } from 'vuex-class';
import { IProjectsState } from '@/store/modules/projects/types';
import { IAppState } from '@/store/modules/app/types';
import { IProjectMQTTInfo } from '@/types/project.types';
import { IMQTTState } from '@/store/modules/mqtt/types';
import { IRecord } from '@/types/devices.types';
import InstallationWizardInfoPopup from '@/ui/components/components/InstallationWizardInfoPopup.vue';
import { IMQTTClientInfo } from '@/types/mqtt.types';

/**
 * Project page.
 * This page is wrapper of Favorites, Areas, Devices Library, AIML Library, Variables,
 * Workbench, Settings, Rules, Report, Event List pages
 */
@Component({
  components: {
    CircleSpinner,
    InstallationWizardInfoPopup,
  },
})
export default class Project extends Vue {
  @State('projects') projectsState!: IProjectsState;
  @State('app') appState!: IAppState;
  @State('mqttClient') mqttState!: IMQTTState;
  @Getter('mqttClient/mqttClientInfo') mqttClientInfo!: IMQTTClientInfo;
  @Mutation('measurements/setMeasurement') setMeasurement!: (record: IRecord) => void;
  @Mutation('projects/setMqttInfo') setMqttInfo!: (info: IProjectMQTTInfo) => void;
  @Mutation('projects/setProjectId') setProjectId!: (route: string) => void;
  @Mutation('projects/setProjectLoader') setProjectLoader!: (status: boolean) => void;
  @Mutation('mqttClient/setLastHeartbeatMessage') setLastHeartbeatMessage!: (value: number | null) => void;
  @Action('projects/loadProject') loadProject!: (projectId: string) => Promise<void>;
  @Action('app/clear') clear!: () => void;
  @Action('members/fetchMember') fetchMember!: (payload: { memberId: string; projectId: string }) => Promise<void>;
  @Action('events/loadEvents') loadEvents!: (payload: { page: number; bol: boolean }) => Promise<void>;
  @Action('mqttClient/createConnection') createConnection!: () => Promise<void>;
  @Action('mqttClient/removeConnection') removeConnection!: () => Promise<void>;

  isProjectReady = false

  get lastConnectivity() {
    const lastHeartbeatMessage = this.mqttState.lastHeartbeatMessage !== 0 ? this.mqttState.lastHeartbeatMessage : this.project.createdAt;
    return lastHeartbeatMessage ? new Date(lastHeartbeatMessage).getTime() : 0;
  }

  get project() {
    return this.projectsState.project;
  }
  get user() {
    return this.appState.user;
  }

  get projectLoader() {
    return this.projectsState.projectLoader;
  }

  async created() {
    this.setProjectLoader(true);
    this.setProjectId(this.$route.params.id);
    await this.loadProject(this.$route.params.id);
    await this.fetchMember({ memberId: this.user.id, projectId: this.$route.params.id });
    await this.loadEvents({ page: 1, bol: false });
    this.isProjectReady = true;
    this.setProjectLoader(false);
    // check last heartbeat message
    this.setLastHeartbeatMessage(this.lastConnectivity);
    // connect to MQTT client
    await this.createConnection();
    this.setMqttInfo(this.mqttClientInfo);
  }

  async destroyed() {
    await this.removeConnection();
    this.clear();
  }
}
